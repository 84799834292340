import { ReactNode } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

interface ProtectedRouteProps {
  children: ReactNode;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const [query] = useSearchParams();
  const tenantId = query.get('tenantId');
  const tenantName = query.get('tenantName');

  if (!tenantId || !tenantName) {
    return <Navigate to="/portal-onboarding/404" replace />;
  }
  return <>{children}</>;
}

export default ProtectedRoute;
