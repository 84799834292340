import { createApi } from '@reduxjs/toolkit/query/react';
import { RadiusApiError, apiBaseQuery } from './base';
import { AccountInvitationModel } from '../models/accountInvitation';
import { userDataFromAccountInvitationModel } from '../utils/login';
import { loadState } from '../utils/localStorage';

export interface CreateUserMutationParams {
  invitation: AccountInvitationModel;
}

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: apiBaseQuery('/login/api/v1'),
  tagTypes: ['AccountInvitation'],
  endpoints(builder) {
    return {
      signUp: builder.mutation<{ userId: string }, CreateUserMutationParams>({
        async queryFn(params, queryApi, extraOptions, baseQuery) {
          const tenantName = loadState('tenantName', 'STRING') || 'sandbox_a';

          const createUserResponse = await baseQuery({
            url: `/sign-up`,
            method: 'POST',
            data: userDataFromAccountInvitationModel(
              params.invitation,
              tenantName
            ),
          });

          if (createUserResponse.error) {
            return { error: createUserResponse.error as RadiusApiError };
          }

          return {
            data: { userId: createUserResponse.data as string },
          };
        },
      }),
    };
  },
});

export const { useSignUpMutation } = loginApi;
