import { Box, styled } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.lg,
  width: '100%',
  margin: '0 auto',
  flex: 'auto',
}));

export default Container;
