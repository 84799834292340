import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './rootReducers';
import { Sex } from '../models/accountInvitation';

export interface EligibilityCheckForm {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: Sex;
  street: string;
  city: string;
  state: string;
  zip: string;
}

const initialState: EligibilityCheckForm = {
  id: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  gender: 'male',
  street: '',
  city: '',
  state: '',
  zip: '',
};

const eligibilityCheckSlice = createSlice({
  name: 'eligibilityCheck',
  initialState,
  reducers: {
    setEligibilityCheckForm(
      state,
      action: PayloadAction<EligibilityCheckForm>
    ) {
      return action.payload;
    },
    resetEligibilityCheckForm(state) {
      return initialState;
    },
  },
});

export const { setEligibilityCheckForm, resetEligibilityCheckForm } =
  eligibilityCheckSlice.actions;
export const eligibilityCheckFormSelector = (state: RootState) =>
  state.eligibilityCheck;
export default eligibilityCheckSlice.reducer;
