import { Stack, Typography, styled } from '@mui/material';
import Container from '../Container';
import variables from '../../styles/variables.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

const FooterStyled = styled('header')(({ theme }) => ({
  background: theme.palette.custom.stale,
  minHeight: variables.footerSize,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0 35px',
  color: theme.palette.custom.white,
}));

const StackStyled = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const Footer = () => {
  return (
    <FooterStyled>
      <Container>
        <StackStyled direction="row" gap="21px" alignItems="center">
          <Logo />
          <Typography variant="para14" sx={{ opacity: 0.5 }}>
            © 2023 all.health
          </Typography>
        </StackStyled>
      </Container>
    </FooterStyled>
  );
};

export default Footer;
