import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { loadState } from '../utils/localStorage';

export interface RadiusApiError {
  status?: number;
  data?: unknown;
}

const axiosBaseQuery =
  (
    { baseURL }: { baseURL: string } = {
      baseURL: '',
    }
  ): AxiosBaseQueryFn =>
  async (requestOpts, queryApi) => {
    const tenantId =
      loadState('tenantId', 'STRING') || 'fd147758-7445-5cff-997e-b6257b4de2e0';

    const config = {
      ...requestOpts,
      headers: {
        ...requestOpts.headers,
        tenantId,
      },
    };

    try {
      const result = await axios({ baseURL, ...config });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      if (Number(err?.response?.status) === 401) {
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const apiBaseQuery = (apiPath: string): AxiosBaseQueryFn =>
  axiosBaseQuery({ baseURL: apiPath });

export type AxiosBaseQueryFn = BaseQueryFn<
  AxiosRequestConfig,
  unknown,
  RadiusApiError,
  {},
  {}
>;
