import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from './base';
import {
  AccountInvitationModel,
  CheckForm,
  Sex,
} from '../models/accountInvitation';
import { setEligibilityCheckForm } from '../store/eligibilityCheckSlice';

interface CSVData {
  address_1: string;
  city: string;
  dob: string;
  enrolled_in_medical: string;
  first_name: string;
  gender: string;
  last_name: string;
  member_id: string;
  middle_initial: string;
  process_level: string;
  state: string;
  termination_date: string;
  zipcode: string;
}

export interface CreateUserMutationParams {
  invitation: AccountInvitationModel;
}

export const portalOnboardingApi = createApi({
  reducerPath: 'portalOnboardingApi',
  baseQuery: apiBaseQuery('/portal-onboarding/api/v1'),
  tagTypes: ['AccountInvitation'],
  endpoints(builder) {
    return {
      checkEligibility: builder.query<boolean, CheckForm>({
        async queryFn(params, queryApi, extraOptions, baseQuery) {
          try {
            const dataResponse = await baseQuery({
              url: `/find-user`,
              method: 'POST',
              data: {
                employeeId: params.id,
                lastName: params.lastName,
              },
            });

            if (dataResponse.data) {
              const member = dataResponse.data as CSVData;

              if (member) {
                await queryApi.dispatch(
                  setEligibilityCheckForm({
                    id: member.member_id,
                    firstName: member.first_name || params.firstName,
                    lastName: member.last_name || params.lastName,
                    dateOfBirth: member.dob || params.dateOfBirth,
                    gender: (member.gender
                      ? member.gender === 'M'
                        ? 'male'
                        : 'female'
                      : null) as Sex,
                    street: member.address_1 || '',
                    city: member.city || '',
                    state: member.state || '',
                    zip: member.zipcode || '',
                  })
                );
              }

              return { data: !!member.member_id };
            }
          } catch (error) {
            return { data: false };
          }

          return {
            data: false,
          };
        },
      }),
    };
  },
});

export const { useLazyCheckEligibilityQuery } = portalOnboardingApi;
