import { createApi } from '@reduxjs/toolkit/query/react';
import sanitizeHtml from 'sanitize-html';
import { apiBaseQuery } from './base';
import { fetchJsonFile } from '../utils/files';

interface AppConfig {
  program_eligibility: {
    not_eligible_title: string;
    not_eligible_message: string;
  };
}

export interface Config {
  notEligibleTitle: string;
  notEligibleMessage: string;
}

export const configuratorApi = createApi({
  reducerPath: 'configuratorApi',
  baseQuery: apiBaseQuery('/configurator/api/v1'),
  tagTypes: ['AppConfig'],
  endpoints(builder) {
    return {
      getAppConfig: builder.query<Config, void>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const configUrlResponse = await baseQuery({
            url: '/config',
            method: 'GET',
          });

          if (configUrlResponse.error) {
            return { error: configUrlResponse.error };
          }

          try {
            const config: AppConfig = await fetchJsonFile(
              configUrlResponse.data as string
            );

            return {
              data: {
                notEligibleTitle: sanitizeHtml(
                  config?.program_eligibility?.not_eligible_title ||
                    'it looks like you’re not eligible for this program yet...'
                ),
                notEligibleMessage: sanitizeHtml(
                  config?.program_eligibility?.not_eligible_message ||
                    'Please reach out to your HR department.'
                ),
              },
            };
          } catch (error) {
            return { error: { status: undefined, data: error } };
          }
        },
        providesTags: ['AppConfig'],
      }),
    };
  },
});

export const { useGetAppConfigQuery } = configuratorApi;
