import { createTheme, Theme } from '@mui/material/styles';

import GothamSsmBoldWoff from '../assets/fonts/GothamSSm-Bold_Web.woff';
import GothamSsmBoldWoff2 from '../assets/fonts/GothamSSm-Bold_Web.woff2';
import GothamSsmMediumWoff from '../assets/fonts/GothamSSm-Medium_Web.woff';
import GothamSsmMediumWoff2 from '../assets/fonts/GothamSSm-Medium_Web.woff2';
import UtopiaStdRegularOtf from '../assets/fonts/UtopiaStdRegular.otf';

export const configureMuiComponents = (theme: Theme) =>
  createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Gotham SSm';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: url(${GothamSsmMediumWoff2}) format('woff2'), url(${GothamSsmMediumWoff}) format('woff');
          }

          @font-face {
            font-family: 'Gotham SSm';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: url(${GothamSsmBoldWoff2}) format('woff2'), url(${GothamSsmBoldWoff}) format('woff');
          }

          @font-face {
            font-family: 'Utopia Std';
            src: url(${UtopiaStdRegularOtf}) format('opentype');
            font-weight: normal;
            font-style: normal;
          }

          ul {
            listStyle: none;
            margin: 0;
            padding: 0;
          }
          
          h1,h2,h3,h4,h5,h6: {
            margin: 0;
          }
        `,
      },

      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: theme.palette.divider,
            borderBottomWidth: '2px',
          },
        },
      },
    },
  });
