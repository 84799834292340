import { createTheme, Theme } from '@mui/material/styles';

export const configureButtons = (theme: Theme) =>
  createTheme(theme, {
    typography: {
      button: {
        ...theme.typography.para16,
        lineHeight: 1.5,
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'text' },
            style: {
              textTransform: 'uppercase',
              padding: '9px 18px',
              transition: 'none',
              color: theme.palette.text.primary,

              '&.Mui-disabled': {
                background: theme.palette.action.disabledBackground,
                boxShadow: 'none',
                color: theme.palette.text.disabled,
              },
            },
          },
          {
            props: { variant: 'cta' },
            style: {
              boxShadow: '0px 15px 18px -11px rgba(80, 86, 97, 0.2)',
              borderRadius: '6px',
              padding: '12px 24px',
              transition: 'none',
              position: 'relative',
              zIndex: 0,
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '6px',
                opacity: 0,
                transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                zIndex: -1,
              },
              '&:hover': {
                '&.MuiButton-root': {},
                '&::before': {
                  opacity: 1,
                },
              },

              '&.Mui-disabled': {
                background: theme.palette.action.disabledBackground,
                color: theme.palette.action.disabled,
                boxShadow: 'none',
              },
            },
          },

          {
            props: { variant: 'cta', color: 'black' },
            style: {
              color: theme.palette.custom.white,
              background: theme.palette.custom.stale,
              '&:hover': {
                '&.MuiButton-root': {
                  background: theme.palette.custom.stale,
                },
              },
              '&::before': {
                background: theme.palette.custom.stale,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            color: theme.palette.custom.stale,
            '.MuiButton-startIcon svg,.MuiButton-endIcon svg': {
              fontSize: '16px',
            },
          },
        },
      },
    },
  });
