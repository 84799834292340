import { Palette, PaletteMode, createTheme } from '@mui/material';

import { createAppPalette } from './palette';
import { configureMuiComponents } from './mui-components';
import { LIGHT_MODE } from '../constants/global';
import { configureButtons } from './buttons';

export type CustomColors = keyof Palette['custom'];

const createAppTheme = (mode: PaletteMode = LIGHT_MODE) => {
  const theme = createTheme({
    typography: {
      htmlFontSize: 10,
      fontFamily:
        "'Gotham SSm', 'Helvetica Neue', Arial, Helvetica, sans-serif",
      header48: {
        fontFamily: "'Utopia Std'",
        fontSize: '4.8rem',
        fontWeight: 500,
        lineHeight: 1.25,
      },
      header24: {
        fontFamily: "'Utopia Std'",
        fontSize: '2.4rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      header16: {
        fontSize: '1.6rem',
        fontWeight: 600,
        lineHeight: 1.5,
      },
      para16: {
        fontSize: '1.6rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      para14: {
        fontSize: '1.4rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      icon: { fontSize: '1.8rem' },
      body1: { fontSize: '1.6rem' },
      body2: { fontSize: '1.6rem' },
    },
    palette: createAppPalette(mode),
    breakpoints: {
      values: {
        xs: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return configureMuiComponents(configureButtons(theme));
};

export default createAppTheme;
