import { styled } from '@mui/material';
import Container from '../Container';
import variables from '../../styles/variables.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

const HeaderStyled = styled('header')(({ theme }) => ({
  background: theme.palette.custom.stale,
  height: variables.headerSize,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.custom.white,
  padding: '0 35px',
}));

const Header = () => {
  return (
    <HeaderStyled>
      <Container>
        <Logo />
      </Container>
    </HeaderStyled>
  );
};

export default Header;
