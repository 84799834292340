import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import AppThemeProvider from './styles/AppThemeProvider';
import { styled } from '@mui/material';
import variables from './styles/variables.module.scss';
import './App.scss';
import Container from './components/Container';
import Footer from './components/Footer';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from './components/Loader';
import { saveState } from './utils/localStorage';
import ProtectedRoute from './components/ProtectedRoute';

const EligibilityCheck = lazy(() => import('./pages/EligibilityCheck'));
const Registration = lazy(() => import('./pages/Registration'));
const NotEligible = lazy(() => import('./pages/NotEligible'));
const Success = lazy(() => import('./pages/Success'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const NotFound = lazy(() => import('./pages/404'));

const Main = styled('main')(({ theme }) => ({
  background: theme.palette.background.default,
  height: `calc(100% - ${variables.headerSize})`,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const tenantName = searchParams.get('tenantName');
  const tenantId = searchParams.get('tenantId');

  useEffect(() => {
    if (tenantName) {
      saveState('tenantName', tenantName);
    }
    if (tenantId) {
      saveState('tenantId', tenantId);
    }
  }, [tenantId, tenantName]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Header />
            <Main>
              <Container>
                <BrowserRouter>
                  <Suspense fallback={<Loader />}>
                    <Routes>
                      <Route
                        path="/"
                        element={<Navigate to="/portal-onboarding" replace />}
                      />
                      <Route path="/portal-onboarding/*">
                        <Route
                          index
                          element={<Navigate to="eligibility-check" replace />}
                        />
                        <Route
                          path="eligibility-check"
                          element={
                            <ProtectedRoute>
                              <EligibilityCheck />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="registration"
                          element={
                            <ProtectedRoute>
                              <Registration />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="not-eligible" element={<NotEligible />} />
                        <Route path="success" element={<Success />} />
                        <Route
                          path="terms-of-service"
                          element={<TermsOfService />}
                        />
                        <Route path="404" element={<NotFound />} />
                        <Route path="*" element={<NotFound />} />
                      </Route>
                    </Routes>
                  </Suspense>
                </BrowserRouter>
              </Container>
              <Footer />
            </Main>
          </LocalizationProvider>
        </AppThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
