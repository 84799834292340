import { useMemo } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import createAppTheme from './theme';
import { ColorModeContext } from './color-mode';

export default function AppThemeProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const contextValue = useMemo(
    () => ({
      toggleColorMode: () => {},
    }),
    []
  );

  const theme = useMemo(() => createAppTheme('light'), []);

  return (
    <ColorModeContext.Provider value={contextValue}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </ColorModeContext.Provider>
  );
}
