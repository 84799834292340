import { PaletteOptions, PaletteMode } from '@mui/material';
import variables from './variables.module.scss';

export const createAppPalette: (mode: PaletteMode) => PaletteOptions = (
  mode
) => {
  return {
    mode,
    primary: {
      main: variables.stale,
      light: variables.white,
    },
    secondary: {
      main: variables.white,
      light: variables.stale,
    },
    text: {
      primary: variables.stale,
      secondary: variables.grey1,
      disabled: variables.grey2,
      success: variables.sage2,
    },
    background: {
      default: variables.grey1,
      paper: variables.white,
    },
    action: {
      active: variables.stale,
      hover: variables.stale,
      selected: variables.stale,
      disabled: variables.white,
      disabledBackground: variables.grey2,
    },
    divider: variables.stale,
    custom: {
      white: variables.white,
      stale: variables.stale,
      grey1: variables.grey1,
      grey2: variables.grey2,
      sage2: variables.sage2,
    },
  };
};
