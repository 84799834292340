import dayjs from 'dayjs';
import {
  AccountInvitationModel,
  MemberInvitationModel,
} from '../models/accountInvitation';
import { CreateUserRequestPayload } from '../models/admin';
import { cmToFtIn, ftInToCm, kgToLbs, lbsToKg } from './conversions';
import { User } from '../models/user';

export function userDataFromAccountInvitationModel(
  invitation: AccountInvitationModel,
  tenantName: string
): CreateUserRequestPayload {
  return {
    tenantName,
    roles: serverRolesFromAccountInvitationModel(invitation),
    email: invitation.email.trim(),
    phoneNumber: invitation.phone.trim(),
    title: null,
    givenName: invitation.firstName.trim(),
    familyName: invitation.lastName.trim(),
    middleNames: null,
    dateOfBirth: invitation.dateOfBirth
      ? dayjs(invitation.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : null,
    sex: invitation.sex,
    height: heightInMetersFromHeightInUnits(invitation.height),
    heightDisplayUnits: serverHeightUnitsFromClientHeightUnits(
      invitation.heightDisplayUnits
    ),
    weight: weightInKilogramsFromWeightInUnits(
      invitation.weight,
      invitation.weightDisplayUnits
    ),
    weightDisplayUnits: serverWeightUnitsFromClientWeightUnits(
      invitation.weightDisplayUnits
    ),
    streetAddress: invitation.street
      ? `${invitation.street.trim()} ${invitation.street2?.trim()}`
      : null,
    city: invitation.city ? invitation.city.trim() : null,
    state: invitation.stateOrRegion,
    zip: invitation.zip ? invitation.zip.trim() : null,
    countryCode: invitation.country?.code ?? null,
    countryName: invitation.country?.name ?? null,
    password: null,
    preferences: {
      fh: {
        wristSize: invitation.wristSize ?? 'small',
        bandShipped: invitation.bandShipped,
        informationAccurate: invitation.informationAccurate,
        termsOfService: invitation.termsOfService,
      },
    },
    triggerInvitation: false,
    employeeId: invitation.id,
  };
}

export function serverRolesFromAccountInvitationModel(
  invitation: AccountInvitationModel
): string[] {
  return ['patient'];
}

export function serverHeightUnitsFromClientHeightUnits(
  clientUnits: MemberInvitationModel['heightDisplayUnits']
) {
  return clientUnits === 'ft/in' ? ['FOOT', 'INCH'] : ['CENTIMETER'];
}

export function clientHeightUnitsFromServerHeightUnits(
  serverUnits?: string[]
): MemberInvitationModel['heightDisplayUnits'] {
  return Array.isArray(serverUnits) &&
    serverUnits.length === 1 &&
    serverUnits[0] === 'CENTIMETER'
    ? 'cm'
    : 'ft/in';
}

export function serverWeightUnitsFromClientWeightUnits(
  clientUnits: MemberInvitationModel['weightDisplayUnits']
) {
  return clientUnits === 'lbs' ? ['POUND'] : ['KILOGRAM'];
}

export function clientWeightUnitsFromServerWeightUnits(
  serverUnits?: string[]
): MemberInvitationModel['weightDisplayUnits'] {
  return Array.isArray(serverUnits) &&
    serverUnits.length === 1 &&
    serverUnits[0] === 'KILOGRAM'
    ? 'kg'
    : 'lbs';
}

export function weightInKilogramsFromWeightInUnits(
  value: number | null,
  units: MemberInvitationModel['weightDisplayUnits']
) {
  if (value === null) {
    return null;
  }

  if (units === 'kg') {
    return value;
  }

  return lbsToKg(value);
}

export function weightInUnitsFromWeightInKilograms(
  weightInKg: number | undefined,
  desiredUnits: MemberInvitationModel['weightDisplayUnits']
) {
  if (typeof weightInKg !== 'number') {
    return null;
  }
  if (desiredUnits === 'kg') {
    return weightInKg;
  }
  return kgToLbs(weightInKg);
}

export function heightInMetersFromHeightInUnits(
  value: number | null | Array<number | null>
) {
  if (value === null) {
    return null;
  }

  if (typeof value === 'number') {
    // value is in cm
    return value / 100;
  }

  const cm = ftInToCm(value);
  return cm && cm / 100;
}

export function heightInUnitsFromHeightInMeters(
  heightInMeters: number | undefined,
  desiredUnits: MemberInvitationModel['heightDisplayUnits']
) {
  if (typeof heightInMeters !== 'number') {
    return null;
  }
  if (desiredUnits === 'cm') {
    return heightInMeters * 100;
  }
  return cmToFtIn(heightInMeters * 100);
}

export function memberHeightAndWeightFromUserRecord(user: User) {
  return {
    height: heightInUnitsFromHeightInMeters(
      user.bioInfo?.heightInMeters,
      clientHeightUnitsFromServerHeightUnits(
        user.personalInfo.heightDisplayUnits
      )
    ),
    weight: weightInUnitsFromWeightInKilograms(
      user.bioInfo?.weightInKilograms,
      clientWeightUnitsFromServerWeightUnits(
        user.personalInfo.weightDisplayUnits
      )
    ),
    heightDisplayUnits: clientHeightUnitsFromServerHeightUnits(
      user.personalInfo.heightDisplayUnits
    ),
    weightDisplayUnits: clientWeightUnitsFromServerWeightUnits(
      user.personalInfo.weightDisplayUnits
    ),
  };
}
