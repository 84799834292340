import { combineReducers } from '@reduxjs/toolkit';

import eligibilityCheckReducer from './eligibilityCheckSlice';
import { loginApi } from '../services/login';
import { portalOnboardingApi } from '../services/portal-onboarding';
import { configuratorApi } from '../services/configurator';

const rootReducer = combineReducers({
  [loginApi.reducerPath]: loginApi.reducer,
  [portalOnboardingApi.reducerPath]: portalOnboardingApi.reducer,
  [configuratorApi.reducerPath]: configuratorApi.reducer,
  eligibilityCheck: eligibilityCheckReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
